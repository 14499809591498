import { Controller } from "@hotwired/stimulus"
import { get } from '../utils/api.js';

export default class extends Controller {
  static values = { url: String }

  initialize() {
    this.originalTitle = document.title;
  }

  connect() {
    this.updateBadge();

    this.handler = this.updateBadge.bind(this);
    this.intervalRef = setInterval(this.handler, 10000);
  }

  updateBadge() {
    get(this.urlValue)
      .then((data, _status, _xhr) => {
        data.html.then(html => this.element.innerHTML = html)
      });
  }

  updateTitle(unreadCount) {
    if (unreadCount < 1) { return }

    document.title = `(${unreadCount}) - ${this.originalTitle}`;
  }

  disconnect() {
    clearInterval(this.intervalRef);
  }
}
