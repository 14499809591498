import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "sidebar", "menu" ]

  toggleSidebar(e) {
    e.preventDefault();

    const styles = window.getComputedStyle(this.sidebarTarget);

    if(styles.display === 'none') {
      this.sidebarTarget.style.display = 'flex';
    } else {
      this.sidebarTarget.style.display = 'none';
    }
  }
}
