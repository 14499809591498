import { Controller } from "@hotwired/stimulus";
import { post } from '../utils/api.js';
import {Sortable} from '@shopify/draggable';

export default class extends Controller {
  static targets = ['']

  connect() {
    this.stackedLists = new Sortable(this.element.querySelectorAll('.todo-items__remaining'), {
      draggable: '.todo-item--draggable',
      // handle: '.todo-item--draggable',
      delay: 200,
      classes: {
        'draggable:over': 'todo-item--draggable-over',
        'mirror': 'todo-item__mirror'
      }
    }).on('sortable:stop', event => this.draggingEnds(event));
  }

  draggingEnds(event) {
    if(event.newIndex === event.oldIndex && event.newContainer === event.oldContainer) {
      return;
    }

    const todoListId = event.newContainer.parentNode.dataset['todoListId'];
    const moveUrl = event.dragEvent.source.dataset['moveUrl'];

    post(moveUrl, { todo_item: { position: event.newIndex + 1, todo_list_id: todoListId } });
  }
}
