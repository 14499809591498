

import { Controller } from "@hotwired/stimulus";

window.chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: '#28a745',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

export default class extends Controller {
  static targets = [ 'chartBox' ]

  connect() {
    // new Chart(this.chartBoxTarget, {
    //   type: 'line',
    //   data: {
    //     labels: ["Ian", "Feb", "Mar", "Apr", "Mai", "Iun", "Iul", "Aug", "Sep", "Oct", "Noi", "Dec"],
    //     datasets: [
    //       {
    //         label: 'Venit',
    //         data: [2200, 2000, 2200, 2100, 2250, 2500, 2200, 2550, 2550, 2450, 1100, 2200],
    //         backgroundColor: window.chartColors.blue,
    //         borderColor: window.chartColors.blue,
    //         borderWidth: 3,
    //         fill: false
    //       },
    //       {
    //         label: 'Cheltuieli',
    //         data: [220, 200, 220, 210, 225, 250, 220, 255, 255, 245, 110, 220],
    //         backgroundColor: window.chartColors.red,
    //         borderColor: window.chartColors.red,
    //         borderWidth: 3,
    //         fill: false
    //       },
    //       {
    //         label: 'Profit',
    //         data: [2200, 2000, 2200, 2100, 2250, 2500, 2200, 2550, 2550, 2450, 1100, 2200].map(n => n - n*0.1),
    //         backgroundColor: window.chartColors.green,
    //         borderColor: window.chartColors.green,
    //         borderWidth: 3,
    //         fill: false
    //       }
    //     ]
    //   },
    //   options: {
    //     responsive: true,
    //     title: {
    //       display: false,
    //       text: 'Company Activity'
    //     },
    //     legend: {
    //        display: false
    //     },
    //     tooltips: {
    //       mode: 'index',
    //       intersect: false,
    //     },
    //     hover: {
    //       mode: 'nearest',
    //       intersect: true
    //     },
    //     scales: {
    //       xAxes: [{
    //         display: true,
    //         scaleLabel: {
    //           display: false,
    //           labelString: 'Month'
    //         }
    //       }],
    //       yAxes: [{
    //         display: true,
    //         scaleLabel: {
    //           display: false,
    //           labelString: 'Amount'
    //         }
    //       }]
    //     }
    //   }
    // });
  }
}
