import SparkMD5 from "spark-md5"

const fileSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
// chunk size 99mb
const CHUNK_SIZE = 1024 * 1024 * 99;

export default class FileChecksum {
  static generate(file, callback) {
    const checksum = new FileChecksum(file)
    checksum.generate(callback)
  }

  constructor(file) {
    this.file = file;

    this.chunkSize  = CHUNK_SIZE;
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize)
    this.chunkIndex = 0
  }

  generate(callback) {
    this.callback = callback
    this.md5Buffer = new SparkMD5.ArrayBuffer();
    this.fileReader = new FileReader();

    this.fileReader.addEventListener("load", event => this.#onFileReaderLoad(event));
    this.fileReader.addEventListener("error", event => this.#onFileReaderError(event));

    this.#readNextChunk()
  }

  #onFileReaderLoad(event) {
    this.md5Buffer.append(event.target.result)

    if (!this.#readNextChunk()) {
      const binaryDigest = this.md5Buffer.end(true)
      const base64digest = btoa(binaryDigest)
      this.callback(null, base64digest)
    }
  }

  #onFileReaderError(event) {
    this.callback(`Error reading ${this.file.name} => ${event}`)
  }

  #readNextChunk() {
    if (this.chunkIndex < this.chunkCount || (this.chunkIndex == 0 && this.chunkCount == 0)) {
      const start = this.chunkIndex * this.chunkSize
      const end = Math.min(start + this.chunkSize, this.file.size)
      const bytes = fileSlice.call(this.file, start, end)
      this.fileReader.readAsArrayBuffer(bytes)
      this.chunkIndex++

      return true
    } else {
      return false
    }
  }
}
