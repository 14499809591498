import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = [ "input", "dropzone" ]

  connect() {
    this.dropzoneTarget.addEventListener("dragover", this.onDragOver.bind(this), false);
    this.dropzoneTarget.addEventListener("drop", this.onDrop.bind(this), false);
    this.dropzoneTarget.addEventListener("dragleave", this.onDragLeave.bind(this), false);
  }

  onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy';

    this.dropzoneTarget.classList.add("bg-gray-200");
  }

  onDrop(e) {
    e.stopPropagation();
    e.preventDefault();

    this.inputTarget.files = e.dataTransfer.files;

    this.dropzoneTarget.classList.remove("bg-gray-200");

    this.inputTarget.dispatchEvent(new Event("change"));
  }

  onDragLeave(e) {
    e.stopPropagation();
    e.preventDefault();

    this.dropzoneTarget.classList.remove("bg-gray-200");
  }
}
