import { Controller } from "@hotwired/stimulus";
import { post } from '../utils/api.js';
import { Sortable } from '@shopify/draggable';

export default class extends Controller {
  static targets = ['']

  connect() {
    this.stackedLists = new Sortable(this.element, {
      draggable: `.todo-list--draggable`,
      handle: '.todo-list__header',
      delay: 200,
      classes: {
        'draggable:over': 'todo-list--draggable-over',
        'mirror': 'todo-list__mirror'
      }
    }).on('sortable:stop', event => this.draggingEnds(event));
  }

  draggingEnds(event) {
    if(event.newIndex === event.oldIndex && event.newContainer === event.oldContainer) {
      return;
    }

    const moveUrl = event.dragEvent.source.dataset['moveUrl'];

    post(moveUrl, { todo_list: { position: event.newIndex + 1 } });
  }
}
