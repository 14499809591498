import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectedFiles", "title"]

  selectFiles() {
    const file = this.selectedFilesTarget.files[0];

    if(!this.titleTarget.value) {
      this.titleTarget.value = file.name.replace(/\.[^/.]+$/, '').replace(/_/g, ' ');
    }
  }
}
