export function applyToItems(elementOrElements, func) {
  if (elementOrElements.length === undefined) {
    elementOrElements = [elementOrElements]
  }

  elementOrElements.forEach(func);
}

export function addClasses(elements, classesName) {
  console.log(classesName)
  const classes = classesName.length === undefined ? classesName.split(" ") : classesName;

  applyToItems(elements, (element) => {
    classes.forEach((className) => {
      element.classList.add(className);
    });
  });
}

export function removeClasses(elements, classesName) {
  console.log(classesName)
  const classes = classesName.length === undefined ? classesName.split(" ") : classesName;

  applyToItems(elements, (element) => {
    classes.forEach((className) => {
      element.classList.remove(className);
    });
  });
}
