import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  connect() {

  }

  change() {
    this.element.submit();
  }

  delayChange() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => this.change(), 500);
  }
}
