import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "filesField", "message" ]

  connect() {
    this.element.addEventListener('dragenter', this.handleDragEnter.bind(this), false);
    this.element.addEventListener('drop', this.handleDrop.bind(this), false);
  }

  handleDragEnter(event) {
    console.log(event.dataTransfer.types);
  }

  handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.filesFieldTarget.files = event.dataTransfer.files;
  }
}
