import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {
    this.element.addEventListener(
      'ajax:complete',
      event => this.replaceWithResponse(event)
    );
  }

  replaceWithResponse({ detail: [xhr, status]}) {
    var container = document.createElement('div');

    container.innerHTML = xhr.response;

    this.element.parentNode.replaceChild(container, this.element);
  }
}
