import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.targetId = this.element.dataset.target;

    this.element.addEventListener('click', this.toggle.bind(this));
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();

    document.getElementById(this.targetId).classList.toggle('collapse');
  }
}
