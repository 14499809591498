import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'allDay', 'endDateContainer', 'endDateInput',
    'startDateContainer', 'startDateInput', 'title'
  ]

  connect() {
    this.changeAllDay();
  }

  changeAllDay() {
    this.toogleTime(this.allDayTarget.checked);
  }

  toogleTime(shouldHide) {
    let separator = this.element.getElementsByClassName('calendar-event-field__separator')[0];
    let timeContainer = this.element.getElementsByClassName('calendar-event-field__time')[0];

    if(shouldHide) {
      separator.classList.add('d-none');
      timeContainer.classList.add('d-none');
      separator.classList.remove('d-inline-block');
      timeContainer.classList.remove('d-inline-block');
    } else {
      separator.classList.add('d-inline-block');
      timeContainer.classList.add('d-inline-block');
      separator.classList.remove('d-none');
      timeContainer.classList.remove('d-none');
    }
  }
}
