import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.handler = this.#handleFinished.bind(this);
    this.element.addEventListener('turbo:submit-end', this.handler);

    console.log(this.element);
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.handler);
  }

  #handleFinished(event) {
    this.element.reset();
  }
}
