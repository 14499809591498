import { post as railsPost, get as railsGet, destroy as railsDestroy } from '@rails/request.js';

export function post(url, body) {
  return railsPost(url, {
    body: JSON.stringify(body),
    contentType: "application/json",
  });
}

export function get(url) {
  return railsGet(url, {
    accept: "application/json",
  });
}

export function destroy(url) {
  return railsDestroy(url, {
    accept: "application/json",
  });
}
