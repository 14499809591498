import { Controller } from "@hotwired/stimulus";
import { post, destroy } from '../utils/api.js';

export default class extends Controller {
  changeCompletion(event) {
    if (event.target.checked) {
      post(this.element.dataset.completionUrl, {});
    }
    else {
      destroy(this.element.dataset.completionUrl);
    }
  }

  changeCompletitionByShowPage(event) {
    event.preventDefault();
    event.stopPropagation();

    const checked = this.element.classList.contains('todo-item__completion--checked')

    const onSuccess = () => {
      if(checked) {
        this.element.classList.remove('todo-item__completion--checked');
        this.element.classList.add('todo-item__completion--unchecked');
      } else {
        this.element.classList.add('todo-item__completion--checked');
        this.element.classList.remove('todo-item__completion--unchecked');
      }
    }

    if(checked) {
      destroy(event.target.action).then(onSuccess);
    }
    else {
      post(event.target.action, {}).then(onSuccess);
    }
  }
}
