import { Controller } from "@hotwired/stimulus";
import FileChecksum from "../utils/file_checksum";
import { numberToHumanSize } from "../utils/helper";

export default class extends Controller {
  static targets = [
    "uploadForm", "uploadBtn", "container", "uploadEntryTemplate"
  ];
  static values = {
    checkUrl: String,
    uploadUrl: String,
    redirectUrl: String,
    icons: Object
  };

  connect() {
    this.filesToUpload = []

    this.element.addEventListener("dragover", this.onDragOver.bind(this), false);
    this.element.addEventListener("drop", this.onDrop.bind(this), false);
    this.element.addEventListener("dragleave", this.onDragLeave.bind(this), false);
  }

  imgTag(file) {
    const ext = file.name.split('.').pop();
    const url = this.iconsValue[ext] || this.iconsValue["file"];

    return `<img src="${url}" width="24" />`;
  }

  onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy';

    this.element.classList.add("bg-gray-200");
  }

  onDrop(e) {
    e.stopPropagation();
    e.preventDefault();

    this.onFilesSelected({ target: { files: e.dataTransfer.files } })

    this.element.classList.remove("bg-gray-200");
  }

  onDragLeave(e) {
    e.stopPropagation();
    e.preventDefault();

    this.element.classList.remove("bg-gray-200");
  }

  onFilesSelected(e) {
    const newFiles = Array.from(e.target.files).map(file => {
      return { file: file, exists: false, err: null };
    });

    this.filesToUpload = this.filesToUpload.concat(newFiles);

    this.containerTarget.innerHTML = "";

    this.filesChecks = this.filesToUpload.map(file => this.addFileAndCheck(file));

    Promise.all(this.filesChecks).then(() => {
      this.uploadBtnTarget.classList.remove("hidden");
    });

    this.containerTarget.classList.remove("hidden");
  }

  uploadFiles(e) {
    e.preventDefault();

    this.uploadBtnTarget.innerHTML = "Uploading...";

    const newFilesToUpload = this.filesToUpload.filter(file => !file.exists);

    Promise.all(newFilesToUpload.map(file => {
      const formData = new FormData();

      formData.append("upload[files][]", file.file);
      formData.append("authenticity_token", document.head.querySelector("[name='csrf-token']").content);

      return fetch(this.uploadUrlValue, {
        method: 'POST',
        body: formData
      }).then(() => {
        window.location.href = this.redirectUrlValue;
      });
    }))
    .then(() => {
      this.uploadBtnTarget.innerHTML = "Upload";
      this.filesToUpload = [];
    });
  }

  addFileAndCheck(file) {
    return new Promise((resolve, reject) => {
      const template = this.uploadEntryTemplateTarget.content.cloneNode(true);

      file.id = "file-" + Math.random().toString(36).substr(2, 9);

      template.querySelectorAll("[data-file-name]")
              .forEach(node => node.innerHTML = file.file.name);

      template.querySelectorAll("[data-file-checksum]")
              .forEach(node => node.id = file.id);

      template.querySelectorAll("[data-file-size]")
              .forEach(node => node.innerHTML = numberToHumanSize(file.file.size));

      template.querySelectorAll("[data-file-type]")
              .forEach(node => node.innerHTML = this.imgTag(file.file));

      template.querySelectorAll("[data-file-status]")
              .forEach(node => {
                node.innerHTML = "<p class='text-blue-600'>Verificare...</p>";
              });

      this.containerTarget.appendChild(template);

      FileChecksum.generate(file.file, (err, checksum) => {
        const elem = document.getElementById(file.id);
        const statusElem = elem.querySelector("[data-file-status]");

        file.checksum = checksum;

        if (err) {
          statusElem.innerHTML = "<p class='text-red-800 font-bold'>ERROR</p>";

          file.err = err;

          reject(err);
        } else {
          elem.dataset.fileChecksum = checksum;

          fetch(
            this.checkUrlValue + "?checksum=" + encodeURIComponent(checksum),
            { method: 'GET' }
          )
          .then(response => response.json())
          .then(data => {
            file.exists = data.exists;

            if (data.exists) {
              statusElem.innerHTML = "<p class=\"badge-red font-bold\">Exista</p>";
            }
            else {
              statusElem.innerHTML = "<p class=\"badge-green font-bold\">Nou</p>";
            }

            resolve(file);
          }).catch(err => {
            file.err = err;
            reject(err);
          });
        }
      });
    });
  }
}
