import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["todoItemsContainer", "todoItemFormContainer", "todoItemAddBtn"]

  connect() {
    this.element.addEventListener('ajax:complete', this.todoItemCreated.bind(this));
  }

  toggleAddItem() {
    this.todoItemFormTemplate = this.todoItemFormTemplate || this.todoItemFormContainerTarget.innerHTML;

    this.todoItemFormContainerTarget.classList.toggle('d-none');
    this.todoItemAddBtnTarget.classList.toggle('d-none');
  }

  todoItemCreated(event) {
    event.stopPropagation()

    const xhr = event.detail[0];

    this.todoItemsContainerTarget.insertAdjacentHTML('beforeend', xhr.response);
    this.todoItemFormContainerTarget.innerHTML = this.todoItemFormTemplate;
  }
}
