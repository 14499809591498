import { Controller } from "@hotwired/stimulus"
import { addClasses, removeClasses } from "../utils/elements"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ["source"]
  static values = { value: String }
  static classes = ["copied"]

  copy(event) {
    event.preventDefault()

    if (this.hasValueValue) {
      navigator.clipboard.writeText(this.valueValue);
    }
    else {
      navigator.clipboard.writeText(this.sourceTarget.value);
    }

    addClasses(this.element, this.copiedClasses);
    const oldValue = this.element.innerHTML;
    const oldHeight = this.element.offsetHeight;

    this.element.innerHTML = "Copiat!";
    this.element.style.height = `${oldHeight}px`;

    setTimeout(() => {
      removeClasses(this.element, this.copiedClasses);
      this.element.innerHTML = oldValue;
    }, 1000);
  }
}
